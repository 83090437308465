import React from 'react'
import { Link } from 'gatsby'

const PillarComponent = ({ posts }) => {
  // Types can be
  // Signals
  // Messaging
  // Problem - stack
  // Pitching
  // Pricing
  return (
    <div className="pillar-container">
      {posts?.map(({ node }) => {
        return (
          <li key={node.fields.slug}>
            <Link to={node.fields?.slug}>{node.fields?.title}</Link>
            {/* <p>{node?.excerpt}</p> */}
          </li>
        )
      })}
    </div>
  )
}

export default PillarComponent
